/*------------------------------------------------------------------
[Master Script]

Author:     Jimi Robaer
Version:	0.1.0
-------------------------------------------------------------------*/

import "sanitize.css";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import Sections from "./Sections";

const WebFont = require("webfontloader");

window.onload = () => {
    Site.init();
};

var Site = {
    init: function init() {
        Site.webfont_loader();
        Site.initSections();
        Site.initScrollIndicator();
        Site.initPreloader();
    },

    webfont_loader: function webfont_loader() {
        /**
         * Web Font Loader gives you added control when using linked fonts via @font-face.
         * Use modules: https://github.com/typekit/webfontloader#modules
         */

        WebFont.load({
            // google: {
            //     families: [""],
            // },
            // typekit: {
            //     id: ''
            // },
            custom: {
                families: ["ApfelGrotezk"],
            },
            active: Site.initPreloader(),
        });
    },

    initPreloader() {
        gsap.to(document.querySelector("main"), {
            opacity: 1,
            delay: 0.5,
        });
    },

    initSections() {
        const sectionEls = document.querySelectorAll("main .section");
        const videoEl = document.querySelector(".background-video");
        const formEl = document.querySelector(".js-override-form");
        const inputEl = document.querySelector(".js-override-input");
        new Sections({
            elements: sectionEls,
            videoEl: videoEl,
            formEl: formEl,
            inputEl: inputEl,
        });
    },

    initScrollIndicator() {
        const indicatorEl = document.querySelector(".js-indicator");

        gsap.to(indicatorEl, {
            opacity: 0,
            scrollTrigger: {
                trigger: "body",
                start: "top -20%",
                end: "top -20%",
                toggleActions: "play none reverse none",
            },
        });
    },
};
