import gsap from "gsap";
import { SplitText } from "gsap/SplitText";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(SplitText, ScrollToPlugin);

export default class Sections {
    constructor(args) {
        this.elements = args.elements || null;
        this.videoEl = args.videoEl || null;
        this.formEl = args.formEl || null;
        this.inputEl = args.inputEl || null;
        if (!this.elements && this.videoEl && this.formEl && this.inputEl)
            return;

        this.currentSection = null;
        this.nextSection = null;

        this.sentence = "I am";
        this.currentInputValue = null;

        this.isAnimating = false;

        this.mainEl = document.querySelector("main");

        this._initialize();
    }

    _initialize() {
        this.currentSection = this.elements[0];
        this.nextSection = this.elements[1];

        this.formEl.reset();

        if ((this.currentSection.classList.contains = "intro")) {
            this.inputEl.addEventListener("beforeinput", this._preventInput);
            this.inputEl.addEventListener(
                "keydown",
                this._overrideInput.bind(this)
            );
            this.formEl.addEventListener("submit", this._preventInput);
        }

        this._splitLines();

        gsap.set(this.elements[1], {
            display: "none",
        });
    }

    _preventInput(event) {
        event.preventDefault();
    }

    _overrideInput(event) {
        const input = event.target;

        if (event.keyCode == 8) {
            input.value = input.value.slice(0, -1);
            return;
        }

        if (input.value == this.sentence) {
            this._nextSection();
            return;
        }

        input.value = this.sentence.slice(0, input.value.length + 1);
    }

    _nextSection() {
        if (this.isAnimating) return;
        this.isAnimating = true;

        const currentSlideTl = gsap.timeline({
            defaults: {
                ease: "power4.out",
            },
        });

        // Outro currentSection: stagger lines

        const currentLineEls = this.currentSection.querySelectorAll(".js-line");

        currentSlideTl.to(currentLineEls, {
            opacity: 0,
            duration: 0.75,
            yPercent: -2.5,
            stagger: 0.25,
        });

        // Switch theme and play video

        currentSlideTl.set(this.mainEl, {
            onComplete: () => {
                this.mainEl.classList.add("theme-dark");
            },
        });

        currentSlideTl.to(this.videoEl, {
            opacity: 1,
            onComplete: () => {
                gsap.set(this.currentSection, {
                    display: "none",
                });
                gsap.set(this.nextSection, {
                    display: "block",
                });
                gsap.set(window, {
                    scrollTo: 0,
                });
            },
        });

        // Intro nextSection: stagger lines

        const nextLineEls = this.nextSection.querySelectorAll(".js-line");

        gsap.set(nextLineEls, {
            opacity: 0,
        });

        currentSlideTl.to(nextLineEls, {
            opacity: 1,
            duration: 0.75,
            yPercent: 2.5,
            stagger: 0.25,
        });
    }

    _splitLines() {
        const splitLineEls = document.querySelectorAll(".js-split-lines");
        if (splitLineEls) {
            splitLineEls.forEach(el => {
                new SplitText(el, {
                    type: "lines",
                    linesClass: "js-line",
                    reduceWhiteSpace: false,
                });
            });
        }
    }

    _playVideo() {}
}
